<template>
  <div id="app">
    <TerminalNavbar />

    <slot />
  </div>
</template>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100dvh;
}
</style>
